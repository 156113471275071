import React from "react";
import "../sass/utils/loader.scss";
function Loader() {
  return (
    <div className="loading">
      <div></div>
    </div>
  );
}

export default Loader;
