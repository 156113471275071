export const validateFields = (fields) => {
  const newErrors = {};
  const { firstName, lastName, address, city, pinCode, email, phoneNo, state } =
    fields;

  if (firstName.length < 2) {
    newErrors.firstName = "Please enter a valid first name.";
  }

  if (lastName.length < 2) {
    newErrors.lastName = "Please enter a valid last name.";
  }

  if (address.length < 5) {
    newErrors.address = "Please enter a valid address.";
  }

  if (city.length < 2) {
    newErrors.city = "Please enter a valid city.";
  }

  // Remove spaces from the pin code and validate its length
  const pinCodeWithoutSpaces = pinCode.replace(/\s/g, "");
  if (pinCodeWithoutSpaces.length < 5 || pinCodeWithoutSpaces.length > 10) {
    newErrors.pinCode =
      "Pin code should be between 5 and 10 characters, excluding spaces.";
  }

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(email)) {
    newErrors.email = "Enter a valid email in format: name@example.com";
  }

  const formattedPhoneNo = phoneNo.replace(/\D/g, "");
  if (formattedPhoneNo.length !== 11) {
    newErrors.phoneNo = "Invalid phone number format.";
  }

  if (!state) {
    newErrors.state = "Please select a state.";
  }

  return newErrors;
};
