import React, { Fragment } from "react";
import "../../sass/components/pdp/relatedProduct.scss";
import Loader from "../../utils/loader";
import AllProductSlider from "../home/allProductSlider";

const RelatedProducts = ({ products, loading }) => {
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="related-products">
          <div className="related-products-heading-container">
            <h3 className="related-product-text">Related Products</h3>
          </div>
          <AllProductSlider products={products} loading={loading} />
        </div>
      )}
    </Fragment>
  );
};
export default RelatedProducts;
