import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTopOnNavigation = () => {
  const { pathname } = useLocation();
  const timeoutRef = useRef(null);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const debounceScroll = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(scrollToTop, 600);
    };

    debounceScroll();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [pathname]);

  return null;
};

export default ScrollToTopOnNavigation;
