import React, { Fragment, useState } from "react";
import "../../sass/components/pdp/reviewSection.scss";
import ReactStars from "react-rating-stars-component";
import { useEffect } from "react";
import Loader from "../../utils/loader";

const ReviewSection = ({ reviews, product, loading }) => {
  const pageSize = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages =
    reviews && Math.ceil(reviews.length > 0 && reviews.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(
    startIndex + pageSize,
    reviews && reviews.length > 0 && reviews.length
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        reviews &&
        reviews.length > 0 && (
          <div className="review-products">
            <div className="review-products-heading-container">
              <h3 className="review-product-text">Product Reviews</h3>
              <div className="review-section">
                <h3 className="review-rating">
                  {product && product.ratings && product.ratings.toFixed(1)}
                </h3>
                <div className="review-number-main">
                  <ReactStars
                    className="react-star"
                    value={product && product.ratings && product.ratings}
                    count={5}
                    size={24}
                    edit={false}
                  />
                  <span className="review-number-text">
                    {product && product.numOfReviews && product.numOfReviews}{" "}
                    reviews
                  </span>
                </div>
              </div>
            </div>
            <div className="break-line"></div>
            <div className="reviews-container">
              {reviews &&
                reviews.map((review, index) => (
                  <div key={index} className="review-item">
                    <div className="review-main">
                      <div className="reviewer">
                        <h3 className="reviewer-name">
                          {review.name && review.name === "User"
                            ? review.email
                            : review.name}
                        </h3>
                      </div>
                      <div className="rating-display-area">
                        <div className="review-rating">
                          <ReactStars
                            count={5}
                            value={review.rating}
                            size={24}
                            edit={false}
                          />
                        </div>
                        <div className="review-comment">{review.comment}</div>
                      </div>
                    </div>
                    {reviews && index !== reviews.length - 1 && (
                      <div className="break-line"></div>
                    )}
                  </div>
                ))}
            </div>
            {totalPages > 1 && (
              <div className="pagination">
                <button
                  className="pagination-btn"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Prev
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    className={`pagination-btn ${
                      i + 1 === currentPage ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  className="pagination-btn"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )
      )}
    </Fragment>
  );
};

export default ReviewSection;
