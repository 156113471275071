import React, { useState } from "react";
import "../../sass/components/pdp/reviewPopup.scss";
import closeIcon from "../../assets/svg/close-icon.svg";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, newReview } from "../../actions/productAction";
import { useEffect } from "react";
import { useAlert } from "react-alert";
import { NEW_REVIEW_RESET } from "../../constants/productConstants";

const ReviewPopup = ({ isOpen, togglePopup, productId }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!rating) {
      alert.error("Please provide a rating before submitting.");
      return;
    }
    if (!comment) {
      alert.error("Please write a comment before submitting.");
      return;
    }
    const myForm = new FormData();
    myForm.set("rating", rating);
    myForm.set("comment", comment);
    myForm.set("productId", productId);

    dispatch(newReview(myForm));
    togglePopup();
  };

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Review Submit Successfully");
      dispatch({ type: NEW_REVIEW_RESET });
    }
  }, [dispatch, reviewError, alert, productId, success]);
  return (
    isOpen && (
      <div className="popup-overlay">
        <form className="popup-content" onSubmit={handleSubmit}>
          <div className="review-heading-section">
            <h2 className="review-heading">Write Your Review</h2>
            <button
              type="button"
              className="remove-item-button"
              onClick={togglePopup}
            >
              <img
                src={closeIcon}
                alt="RemoveIcon"
                className="remove-item-icon"
              />
            </button>
          </div>
          <div className="rating-section">
            {/* Rating stars component */}
            <span className="review-label">Rating: </span>
            <ReactStars
              className="react-star"
              count={5}
              value={rating}
              onChange={(newRating) => setRating(newRating)}
              size={24}
            />
          </div>
          <div className="review-input">
            {/* Review input field */}
            <textarea
              className="review-text"
              name="review"
              placeholder="Write your review..."
              rows="4"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
          <div className="submit-button">
            {/* Submit button */}
            <button type="submit" className="review-submit-button">
              Submit
            </button>
          </div>
        </form>
      </div>
    )
  );
};

export default ReviewPopup;
