import React from "react";

const formatDate = (date) => {
  return date ? new Date(date).toLocaleDateString("en-GB") : null;
};

const DateBasedOnStatus = ({ orderDetails }) => {
  if (!orderDetails) return null;

  const { orderStatus, shippedAt, deliveredAt, createdAt } = orderDetails;

  let formattedDate = null;

  switch (orderStatus) {
    case "Shipped":
      formattedDate = formatDate(shippedAt);
      break;
    case "Delivered":
      formattedDate = formatDate(deliveredAt);
      break;
    case "Processing":
      formattedDate = formatDate(createdAt);
      break;
    default:
      formattedDate = null;
  }

  return (
    formattedDate && (
      <p
        className={
          orderStatus === "Delivered"
            ? "greenColor"
            : orderStatus === "Shipped"
            ? "blueColor"
            : "redColor"
        }
      >
        {"Date: " + formattedDate}
      </p>
    )
  );
};

export default DateBasedOnStatus;
