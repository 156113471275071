import React, { useState, useEffect, Fragment } from "react";
import "../../sass/pages/pdp/product-detail.scss";
import ProductDetailSlider from "../../components/pdp/productDetailSlider";
import ProductDetailPage from "../../components/pdp/productDetailPage";
import Breadcrumb from "../../components/breadcrumb";
import SuggestedProduct from "../../components/pdp/suggestedProduct";
import RelatedProducts from "../../components/pdp/relatedProduct";
import ReviewSection from "../../components/pdp/reviewSection";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getProductDetails,
  getProduct,
} from "../../actions/productAction";
import Loader from "../../utils/loader";
import { useAlert } from "react-alert";
import { useLocation, useParams } from "react-router";

const ProductDetail = () => {
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [suggestionProduct, setSuggestionProducts] = useState();
  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const { products: allProducts } = useSelector((state) => state.homeProducts);
  const { isAuthenticated } = useSelector((state) => state.user);
  const { id } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch product details and related products
    dispatch(getProductDetails(id));
    dispatch(getProduct({ category: product.category }));

    // Clear errors
    if (error) {
      alert.error(error);
      dispatch(clearErrors);
    }
  }, [dispatch, id, product.category, error, alert]);

  useEffect(() => {
    // Update suggestion and related products
    if (allProducts && allProducts.length > 0) {
      const suggestionCategory = allProducts;
      const getSuggestion = allProducts.filter(
        (ele) => ele.category === product.category
      );
      const shuffledProducts = getSuggestion.sort(() => Math.random() - 0.5);

      // const women = allProducts
      //   .filter((product) => product.category === "women")
      //   .splice(0, 3);

      // const kids = allProducts
      //   .filter((product) => product.category === "kids")
      //   .splice(0, 2);

      setSuggestionProducts([...shuffledProducts.slice(0, 8)]);

      const relatedCategory = suggestionCategory.filter(
        (prod) =>
          prod.category === product.category &&
          prod.subCategory === product.subCategory
      );

      setRelatedProducts([...relatedCategory]);
    }
  }, [allProducts, product]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid product-detail-page">
          <Breadcrumb />
          <div className="container custom-container">
            <div className="product-detail-row_one row">
              <div className="col-12 col-md-6 product-slider-container">
                {product.images && (
                  <ProductDetailSlider
                    selectedProductImage={product.images}
                    category={product.category}
                    loading={loading}
                  />
                )}
              </div>
              <div className="col-12 col-md-6">
                <ProductDetailPage
                  product={product}
                  loading={loading}
                  isAuthenticated={isAuthenticated}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <SuggestedProduct
                  products={suggestionProduct}
                  loading={loading}
                />
              </div>
            </div>
            {relatedProducts.length >= 5 && (
              <div className="row">
                <div className="col-12">
                  <RelatedProducts
                    products={relatedProducts}
                    loading={loading}
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12">
                <ReviewSection
                  reviews={product.reviews}
                  product={product}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetail;
