import React, { useState, useEffect, Fragment } from "react";
import "../../sass/components/pdp/productDetail.scss";
import "../../sass/components/colors/colorBox.scss";
import { useProduct } from "../../utils/ProductContext";
import ReactStars from "react-rating-stars-component";
import CustomSelect from "../reuseable/select";
import { addItemsToCart } from "../../actions/cartAction";
import { useDispatch } from "react-redux"; // Importing useDispatch
import { useParams } from "react-router";
import { useAlert } from "react-alert";
import ReviewPopup from "./reviewPopup";
import Loader from "../../utils/loader";

const ProductDetailPage = ({ product, loading, isAuthenticated }) => {
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [quantityOptions, setQuantityOptions] = useState([]);
  const [stockId, setStockId] = useState("");
  const [isSizeAvailable, setIsSizeAvailable] = useState(true); // State to track if the size is available
  const [coloSelected, setColorSelected] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { id } = useParams();
  const alert = useAlert();
  const dispatch = useDispatch();

  // Retrieve color and size values
  const sizeValues = product.stock
    ? [...new Set(product.stock.map((item) => item.size))]
    : [];
  const colorValues = product.stock
    ? [...new Set(product.stock.map((item) => item.color))]
    : [];

  // Filter quantity options based on selected color and size
  useEffect(() => {
    if (selectedColor && selectedSize) {
      const matchingStock = product.stock.find(
        (item) => item.color === selectedColor && item.size === selectedSize
      );

      if (matchingStock) {
        const newQuantityOptions = Array.from(
          { length: matchingStock.quantity },
          (_, index) => {
            const quantity = index + 1;
            return { value: quantity.toString(), label: quantity };
          }
        );
        setQuantityOptions(newQuantityOptions);
        setStockId(matchingStock._id);

        // Check if quantity is available
        setIsSizeAvailable(matchingStock.quantity > 0);
      } else {
        setQuantityOptions([]);
        setIsSizeAvailable(false);
      }
    } else {
      setQuantityOptions([]);
      setIsSizeAvailable(true);
    }
  }, [selectedColor, selectedSize, product.stock]);

  // Handle color click event
  const handleColorClick = (color, event) => {
    event.preventDefault();
    setSelectedColor(color);
    setSelectedSize(null); // Reset selected size when color changes
    setSelectedQuantity(""); // Reset selected quantity when color changes
    setColorSelected(true);
  };

  // Handle size change event
  const handleSizeChange = (size) => {
    setSelectedSize(size);
    setSelectedQuantity(""); // Reset selected quantity when size changes
  };

  // Handle "Add to Cart" button click event
  const handleAddToCart = (e) => {
    e.preventDefault();
    let errorMessage = "";

    if (!selectedColor) {
      errorMessage += "color, ";
    }
    if (!selectedSize) {
      errorMessage += "size, ";
    }
    if (!selectedQuantity) {
      errorMessage += "quantity, ";
    }

    if (errorMessage !== "") {
      // Remove the last comma and space from the error message
      errorMessage = errorMessage.slice(0, -2);
      alert.error(`Please select the following: ${errorMessage}`);
      return;
    }

    if (!isSizeAvailable) {
      alert.error("Selected size is not available.");
      return;
    }

    // Dispatch action to add item to cart
    const selectedQuantityValue = parseInt(selectedQuantity, 10);
    const matchingStock = product.stock.find((item) => item._id === stockId);

    if (matchingStock && matchingStock.quantity !== undefined) {
      const totalQuantity = matchingStock.quantity;

      const updatedItem = {
        id: id,
        selectedSize: selectedSize,
        selectedColor: selectedColor,
        stockId: stockId,
        quantity: selectedQuantityValue,
        totalQuantity: totalQuantity,
      };
      dispatch(addItemsToCart(updatedItem));
      alert.success("Item Added to Cart");
    }

    setSelectedColor(null);
    setSelectedSize(null);
    setSelectedQuantity("");
    setColorSelected(false);
  };

  // Options for ReactStars component
  const options = {
    edit: false,
    color: "rgba(20,20,20,0.1)",
    activeColor: "tomato",
    size: window.innerWidth < 600 ? 20 : 25,
    isHalf: true,
  };

  // CSS classes for CustomSelect component
  const multipleSelectClasses = [
    "custom-select-container",
    "custom-select-label",
    "custom-select-input-box",
    "selected-option-text",
    "custom-dropdown-icon",
    "custom-options-list",
    "custom-options",
  ];

  // Function to handle opening and closing the popup
  const togglePopup = () => {
    isPopupOpen ? setIsPopupOpen(false) : setIsPopupOpen(true);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="product-detail">
          <h1 className="product-detail-heading">{product?.name}</h1>
          <div className="product-prices">
            <div className="product-price">
              <span className="current-price">€{product?.price}</span>
              <span className="tax-label">Tax excluded</span>
            </div>
            <div className="tax-shipping-delivery-label">
              <span className="delivery-information">
                Delivery: 1 to 3 weeks
              </span>
            </div>
          </div>
          <div className="product-description">
            <p>{product?.description}</p>
          </div>
          <div className="product-action">
            <form onSubmit={handleAddToCart}>
              <div className="product-variants">
                <div className="product-variants-items">
                  <div className="color-lable-container">
                    <span className="color-label">Color</span>
                  </div>
                  <div className="select-color-container">
                    {colorValues.map((color, index) => (
                      <button
                        key={index}
                        className={`color-button ${color.toLowerCase()} ${
                          selectedColor === color ? "selected" : ""
                        }`}
                        onClick={(event) => handleColorClick(color, event)}
                      ></button>
                    ))}
                  </div>
                </div>
                {coloSelected && (
                  <div className="product-variants-item">
                    {coloSelected ? (
                      <>
                        <div className="size-lable-container">
                          <span className="size-label">Size</span>
                        </div>
                        <div className="select-size-container">
                          {sizeValues.map((size, index) => (
                            <div
                              key={index}
                              className="TextSelectorButtonWrapper"
                            >
                              <button
                                className={`product-button-size ${
                                  selectedSize === size ? "selected" : ""
                                } ${
                                  !isSizeAvailable ||
                                  !product.stock.some(
                                    (item) =>
                                      item.color === selectedColor &&
                                      item.size === size &&
                                      item.quantity > 0
                                  )
                                    ? "unavailable"
                                    : ""
                                }`} // Apply "unavailable" class if size is not available
                                title=""
                                type="button"
                                onClick={() => handleSizeChange(size)}
                                disabled={
                                  !isSizeAvailable ||
                                  !product.stock.some(
                                    (item) =>
                                      item.color === selectedColor &&
                                      item.size === size &&
                                      item.quantity > 0
                                  )
                                } // Disable button if size is not available
                              >
                                {size}
                              </button>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <span className="normal-span">No sizes available</span>
                    )}
                  </div>
                )}
                {quantityOptions.length !== 0 ? (
                  <CustomSelect
                    labelFor="selectQuantityInput"
                    label="Qty"
                    placeholder="Qty"
                    options={
                      quantityOptions.length > 10
                        ? quantityOptions.slice(0, 10)
                        : quantityOptions
                    }
                    classes={multipleSelectClasses}
                    setSelectedQuantity={setSelectedQuantity}
                    withLabel={true}
                  />
                ) : selectedSize &&
                  selectedColor &&
                  quantityOptions.length === 0 ? (
                  <span className="out-of-stock-text">Out of Stock</span>
                ) : null}
                <div className="product-item-review">
                  <div className="review-label-container">
                    <span className="review-label">Review</span>
                  </div>
                  <div className="d-flex align-content-center justify-content-center product-star">
                    {product.ratings ? (
                      <ReactStars
                        {...options}
                        value={product.ratings}
                        className="fas fa-star"
                      />
                    ) : (
                      <span>No reviews available</span>
                    )}
                  </div>
                </div>
                <div className="break-line"></div>
                <div className="product-add-to-cart">
                  <button className="add-to-cart" type="submit">
                    ADD TO BAG
                  </button>
                </div>
                <div className="break-line"></div>
              </div>
            </form>
          </div>
          {isAuthenticated && (
            <div className="add-review">
              <div className="reviews-adding-area">
                <h3 className="reviews-headding">Reviews</h3>
                <p className="review-text">
                  Be the first to review this product.
                </p>
              </div>
              <button className="add-review-button" onClick={togglePopup}>
                write a Review
              </button>
            </div>
          )}

          <ReviewPopup
            isOpen={isPopupOpen}
            togglePopup={togglePopup}
            productId={id}
          />
        </div>
      )}
    </Fragment>
  );
};

export default ProductDetailPage;
